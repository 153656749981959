<template>
  <div>
    <v-row class="px-0">
      <v-col cols="11" md="7">
        <div class="text-left subtitle black--text" style="margin-top: 2%">
          {{ $t("role_test_label") }}
        </div>
      </v-col>
      <v-col cols="1" md="1" class="text-right px-0">
        <!-- <v-btn icon color="primary" @click="infoDialog = true">
          <v-icon>info</v-icon>
        </v-btn> -->
      </v-col>
      <v-col cols="12" md="4">
        <div class="text-right px-0 mx-6" style="width: 93.8%">
          <v-text-field
            v-model="textSearch"
            append-icon="mdi-magnify"
            :label="$t('cand_search_test')"
            single-line
            hide-details
            dense
            outlined
          ></v-text-field>
        </div>
      </v-col>
    </v-row>
    <v-divider class="my-3"></v-divider>
    <v-row>

      <!-- <pre>
        {{getTests}}
      </pre> -->
      
      <v-col cols="12" md="3" v-for="test in filteredItems" :key="test.id">
        <v-skeleton-loader
          elevation="2"
          v-if="$apollo.loading"
          v-bind="filteredItems"
          type="image, article"
        ></v-skeleton-loader>
        <test-card :test="test" @solve="solveTestDialog" v-else />
      </v-col>
    </v-row>

    <solve-test-dialog
      :dialog="dialogSolveTest"
      :test="test"
      @close="dialogSolveTest = false"
    />
  </div>
</template>

<script>
import { GET_TESTS_CANDIDATE_QUERY } from "./../graphql/Query.service";
import { mapActions, mapGetters } from "vuex";
import SolveTestDialog from "./../components/SolveTestDialog";
import TestCard from "../components/TestCard.vue";
import replaceSpecialCharsMixins from "@/mixins/replaceSpecialChars";
export default {
  components: { SolveTestDialog, TestCard },
  name: "Test",
  mixins: [replaceSpecialCharsMixins],
  data: () => ({
    dialogSolveTest: false,
    filter: {
      limit: 4,
      page: 1,
    },
    test: {},
    tests: [],
     textSearch: undefined,
  }),
  apollo: {
    tests: {
      query: GET_TESTS_CANDIDATE_QUERY,
      variables() {
        return { filterTest: this.filter };
      },
    },
  },
  watch: {
    tests(val) {
      this.setTestsState(val.tests);
    },
  },
  computed: {
    ...mapGetters({
      getTests: "test/getTests",
    }),
    filteredItems() {
      let resultItems = [...this.getTests];
      if (this.textSearch) {
        const text = this.replaceSpecialChars(this.textSearch);
        resultItems = resultItems.filter(
          (t) =>{
          if (t.entity && t.vacancy) {
            return (
              this.replaceSpecialChars(t.entity.name).includes(text) ||
              this.replaceSpecialChars(t.vacancy.title).includes(text)
            );
          } else if (t.entity) {
            return this.replaceSpecialChars(t.entity.name).includes(text);
          } else if (t.vacancy) {
            return this.replaceSpecialChars(t.vacancy.title).includes(text);
          }
        });
      }
      return resultItems;
    },
  },
  methods: {
    ...mapActions({
      setTestsState: "test/setTests",
    }),
    solveTestDialog(test) {
      this.test = test;
      this.dialogSolveTest = true;
    },
  },
};
</script>